import { render, staticRenderFns } from "./CreateNewGame.vue?vue&type=template&id=5d269c26&scoped=true&"
import script from "./CreateNewGame.vue?vue&type=script&lang=js&"
export * from "./CreateNewGame.vue?vue&type=script&lang=js&"
import style0 from "./CreateNewGame.vue?vue&type=style&index=0&id=5d269c26&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d269c26",
  null
  
)

export default component.exports